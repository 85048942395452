import React from 'react'

// import logos
import UberLogo from "../assets/uber-eats.svg"
import GrubhubLogo from "../assets/grubhub.svg"
import DoordashLogo from "../assets/doordash.svg"

const Apps = () => {
  return (
    <div className=" bg-black w-full h-[60px] mt-10" id="menu-section">
      <div className=" container mx-auto h-full flex items-center justify-center gap-x-[15px] md:gap-x-[80px]">
        <img className=" h-[15px]" src={UberLogo} alt="" />
        <img className=" h-[15px]" src={GrubhubLogo} alt="" />
        <img className=" h-[15px]" src={DoordashLogo} alt="" />
      </div>
    </div>
  );
}

export default Apps