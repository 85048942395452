import React from "react";
import LocalPhoto from "../assets/local-photo.jpg";
import { EmailService } from "../services/email.service";

// import components
import TextField from "./TextField";

const Contact = () => {
  const [data, setData] = React.useState({
    name: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const service = new EmailService();
    const results = await service.getAll();

    if (
      data.name.length <= 0 ||
      data.subject.length <= 0 ||
      data.message.length <= 0
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (results.length) {
      const email = results[0].email;

      service.openContact(email, data);
    }
  };

  return (
    <div
      id="contact-section"
      className="w-full  flex flex-col lg:flex-row justify-between overflow-hidden h-[600px] "
    >
      <div className="flex flex-col items-start lg:items-center justify-center flex-1 ">
        <div className="w-full lg:w-[600px] px-10 text-center md:text-start">
          <h1 className=" text-brand font-primary text-4xl md:text-7xl lg:text-5xl">
            Contact us
          </h1>
          <p className=" text-sm mt-5">
            Get it touch and let us know how we can help
          </p>

          <TextField
            onChange={handleChange}
            value={data.name}
            name="name"
            label="Name"
          />
          <TextField
            name="subject"
            onChange={handleChange}
            value={data.subject}
            label="Topic"
          />
          <TextField
            name="message"
            onChange={handleChange}
            value={data.message}
            label="Your message"
          />
          <button className="btn" onClick={handleSubmit}>
            Send Message
          </button>
        </div>
      </div>
      <img className=" hidden lg:flex" src={LocalPhoto} alt="" />
    </div>
  );
};

export default Contact;
