import React from 'react'
import Logo from "../assets/logo.svg"

// import icons
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai"
import { FaTiktok } from "react-icons/fa"


const Footer = () => {
    return (
        <div className='bg-black p-10'>
            <div className='container mx-auto flex justify-between items-center'>
                {/* socialmedias */}
                <div className='flex items-center justify-center gap-x-2'>
                    <a href='https://m.instagram.com/metztli_taqueria/' target='blank'>
                        <AiOutlineInstagram className=' hover:bg-brand cursor-pointer rounded-md text-xl' />
                    </a>
                    <a href='https://www.facebook.com/metztli.taqueria' target='blank'>
                        <AiOutlineFacebook className=' hover:bg-brand cursor-pointer rounded-md text-xl' />
                    </a>
                    <a href='https://m.tiktok.com/@metztli_taqueria' target='blank'>
                        <FaTiktok className=' hover:bg-brand cursor-pointer rounded-md text-xl' />
                    </a>
                </div>
                <img src={Logo} alt="" />
                <p className=' text-sm'>All rights reserved ©2022</p>
            </div>
        </div>
    )
}

export default Footer