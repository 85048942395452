import { BrowserRouter, Route, Routes } from "react-router-dom";

import Homepage from "./pages/Homepage";
import FoodTruckMenu from "./pages/FoodTruckMenu";
import Header from "./components/Header";

function App() {
  return (
    <BrowserRouter>
      <div className="bg-black/95 font-secundary">
        <div className="text-white w-full transition-all duration-500">
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/foodtruck" element={<FoodTruckMenu />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
