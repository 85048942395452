import React, { useEffect, useState } from "react";
import { HeroService } from "../services/hero.service";

// import icons
import { MdKeyboardArrowRight } from "react-icons/md";
import { buildImage } from "../utils/images";

const Hero = () => {
  //state
  const [items, setItems] = useState([]);

  //effects
  useEffect(() => {
    (async () => getAll())();
  }, []);

  //methods

  const getAll = async () => {
    try {
      const results = await new HeroService().getAll();
      setItems(results);
    } catch (error) {
      console.log(error);
    }
  };

  const ViewMenu = () => {
    const element = document.getElementById("menu-section");
    element.scrollIntoView({ behavior: "smooth" });
  }


  return (
    <div className="h-fit pt-10  flex flex-col px-4 container mx-auto">
      <div className="py-10 flex flex-col  lg:flex-row items-center justify-between pr-0 pt-10 lg:pr-[180px]">
        <h1 className=" font-primary text-4xl md:text-7xl text-center md:text-start lg:text-5xl">
          Enjoy <span className=" text-brand">Delicious</span> <br />
          Food and Drinks
        </h1>
        <button onClick={ViewMenu} className="btn relative group mt-5 lg:mt-0">
          View Menu
          <div className="flex items-center -space-x-2">
            <div className=" transition-all h-[2px] group-hover:w-[20px] w-[10px] bg-white"></div>
            <MdKeyboardArrowRight />
          </div>
        </button>
      </div>
      <div className="flex flex-col lg:flex-row items-center gap-x-5 w-full h-auto lg:h-full ">
        <div className=" flex h-[250px] w-full  ">
          {items.map(({ image, alt, imageHover }, index) => {
            return (
              <div
                key={index}
                className=" group  h-full overflow-hidden relative w-full"
              >
                <img
                  className={`${"scale-100 "} transition-all duration-500 w-full object-cover  h-full`}
                  src={buildImage(image).url()}
                  alt={alt}
                />
                {imageHover && (
                  <div
                    className={` ${" w-0"} absolute group-hover:w-full overflow-hidden bg-red-600 top-0 right-0 bottom-0 transition-all duration-500 ease-in-out`}
                  >
                    <img
                      className=" w-full h-full object-cover"
                      src={imageHover}
                      alt={`hover-${alt}`}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

      </div>
      <p className=" w-[70%] pt-5 lg:pt-0 mx-auto lg:mx-0 text-center lg:text-start lg:w-[500px] mt-5 text-sm">
        Enjoy good food and fresh drinks with your friends and family.
        Disfruta de una buena comida y bebida con tus amigos y familias.
      </p>
    </div>
  );
};

export default Hero;
