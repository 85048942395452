import React from "react";

const TextField = ({ label, name, onChange, value }) => {
  const handleClickLabel = () => {
    const input = document.getElementsByName(name);
    if (input) {
      input[0].focus();
    }
  };

  return (
    <div className="flex flex-col justify-start relative my-10">
      <input
        className=" focus:bg-transparent pb-3 peer placeholder-transparent bg-transparent border-b focus:outline-none focus:right-0  border-white/20"
        onChange={onChange}
        placeholder={label}
        value={value}
        type="text"
        name={name}
      />
      <label
        onClick={handleClickLabel}
        className=" cursor-text absolute peer-placeholder-shown:text-white/50 peer-placeholder-shown:translate-y-0 -translate-y-6 transition-all duration-300"
        htmlFor=""
      >
        {label}
      </label>
    </div>
  );
};

export default TextField;
