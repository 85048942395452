import React, { useState } from "react";
import Logo from "../assets/logo.svg";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import componets
import Nav from "./Nav";

// import icons
import TextField from "./TextField";
import { EmailService } from "../services/email.service";

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" p-5 z-50  fixed w-full bg-black  text-sm ">
      <div className="flex container mx-auto justify-between items-center">
        <div className=" items-center w-full flex justify-between md:justify-start ">
          <a href="https://metztlimexicantaqueria.com/" className="flex items-center">
            <img src={Logo} alt="" />
          </a>
          {/* <span onClick={() => setOpen(!open)} className=" ml-10 nav-item">
            Make Reservation
          </span> */}
          <Modal setOpen={setOpen} open={open} />
        </div>
        <Nav />
      </div>
    </div>
  );
};
export default Header;

function Modal({ setOpen, open }) {
  //state
  const [data, setData] = useState({
    name: "",
    date: "",
    time: "",
  });

  const [startDate, setStartDate] = useState();

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDate = (date) => {
    const formatDate = `${date.getDate()}/${date.getMonth() + 1
      }/${date.getFullYear()}`;

    const time = `${date.getHours()}:${date.getMinutes()}`;

    setStartDate(date);

    setData({
      ...data,
      date: formatDate,
      time: time,
    });
  };

  const handleClickSubmit = async () => {
    const service = new EmailService();
    const results = await service.getAll();

    if (
      data.name.length <= 0 ||
      data.date.length <= 0 ||
      data.time.length <= 0
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (results.length) {
      const email = results[0].email;

      service.openReservation(email, data);
    }
  };

  const handleClickLabel = () => {
    const input = document.getElementsByName(`date`);
    if (input) {
      input[0].focus();
    }
  };

  return (
    <>
      {open && (
        <div className=" fixed inset-0  flex items-center justify-center">
          <div
            onClick={() => setOpen(!open)}
            className=" cursor-pointer fixed inset-0 bg-black/90 backdrop-blur-sm "
          ></div>
          <div className=" relative p-8 bg-black text-white border border-white/10 ">
            <h2 className=" font-primary text-2xl">Make Reservation</h2>
            <TextField
              onChange={handleChange}
              name="name"
              value={data.name}
              label="Name"
            />
            <div className="flex flex-col justify-start relative my-10">
              <label
                onClick={handleClickLabel}
                className=" cursor-text absolute peer-placeholder-shown:text-white/50 peer-placeholder-shown:translate-y-0 -translate-y-6 transition-all duration-300"
                htmlFor="date"
              >
                Date
              </label>
              <DatePicker
                name="date"
                className="focus:bg-transparent pb-3 peer placeholder-transparent bg-transparent border-b focus:outline-none focus:right-0  border-white/20"
                selected={startDate}
                showTimeInput
                dateFormat={"dd/MM/yyyy HH:mm"}
                minDate={new Date()}
                minTime={new Date()}
                onChange={handleChangeDate}
              />
            </div>

            <button onClick={handleClickSubmit} className="btn mx-auto mt-5">
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
}
