import React from "react";

const FoodTruckMenu = () => {
  return (
    <div className=" h-screen w-screen flex items-center justify-center">
      FoodTruckMenu
    </div>
  );
};

export default FoodTruckMenu;
