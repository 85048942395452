import React from "react";
import Hero from "../components/Hero";
import Apps from "../components/Apps";
import Menu from "../components/Menu";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <div>
     
      <Hero />
      <Apps />
      <Menu />
      <Contact />
      <Footer />
    </div>
  );
};

export default Homepage;
