import React, { useEffect, useState } from "react";

// import framer motion
import { motion } from "framer-motion";

// import icons from
import { IoMdClose } from "react-icons/io";

import { IoTriangle } from "react-icons/io5";
import { CategoryService } from "../services/category.service";
import { ProductService } from "../services/products.services";
import { buildImage } from "../utils/images";

const Menu = () => {
  //state
  const [active, setActive] = useState({ _id: "" });
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  //effects
  useEffect(() => {
    (async () => getAll())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => getDetails())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  //methods
  const getAll = async () => {
    try {
      const results = await new CategoryService().getAll();
      setCategories(order(results));
      setActive(order(results)[0]);
    } catch (error) {
      alert(
        "ha ocurrido un error cargando categorías, intente de nuevo mas tarde"
      );
      console.log(error);
    }
  };

  const getDetails = async () => {
    try {
      if (!active._id) return;
      const results = await new ProductService().getAll(active._id);
      setProducts(results);
    } catch (error) {
      alert(
        "ha ocurrido un error al cargar los productos, intente de nuevo mas tarde"
      );
      console.log(error);
    }
  };

  const order = (items) => items.sort((a, b) => a.index - b.index);

  //events

  return (
    <div className="  w-full py-10 relative"  >
      <div className=" mx-auto container ">
        <div className="flex gap-x-4 flex-col md:flex-row   ">
          {/* nav */}
          <div className=" mb-10 md:mb-0  mx-auto md:mx-0  md:w-[200px]   ">
            <ul className=" flex md:flex-col flex-wrap justify-center -space-y-0  relative ">
              <div
                style={{ top: `calc( ${active.index * 26}px + 3px)` }}
                className={` w-[2px] cursor-pointer left-0 transition-all duration-500 hidden md:block absolute h-[20px]  bg-brand `}
              ></div>
              {categories.map((item) => {
                return (
                  <li
                    className={` ${active._id === item._id ? "text-brand" : ""
                      } text-xs p-[5px] px-3 mb-5 md:mb-0 md:px-5 cursor-pointer`}
                    onClick={() => setActive(item)}
                    key={item.index}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>

          <MenuSlide category={active} products={products} />
        </div>
      </div>
    </div>
  );
};

export default Menu;

function MenuSlide({ products, category }) {
  const [imageView, setImageView] = useState(false);
  const [image, setImage] = useState("");

  return (
    <motion.div
      key={category.title}
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="px-3 "
    >
      <div className=" flex flex-col ">
        <div className="flex items-center gap-x-8">
          <h1 className=" font-bold text-[#f8f7da] text-3xl md:text-5xl uppercase">
            {category.title}
          </h1>
          {category.price && (
            <span className=" rounded-lg font-bold  bg-brand text-black px-2 text-2xl ">
              ${category.price}
            </span>
          )}
        </div>
        {category.description && (
          <div className="flex items-center gap-x-2">
            <IoTriangle className=" rotate-90 text-brand mt-2" />
            <p className="text-sm mt-3">{category.description}</p>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-5  gap-2 w-full   ">
        {products.map((item, index) => {
          if (!item.image) return (
            <div
              key={index}
              className="flex  flex-col gap-y-2 bg-black p-4 w-full md:w-[190px]"
            >
              {item.image &&
                <img
                  className="md:w-[180px] w-full h-[100px] object-cover"
                  src={buildImage(item.image).url()}
                  alt=""
                />
              }
              <div>
                <span className="font-semibold text-brand uppercase ">
                  {item.title}
                </span>
                <p className="text-xs opacity-60 ">{item.description}</p>
                {item.price &&
                  <span className=" font-semibold text-brand uppercase whitespace-nowrap text-sm">
                    ${item.price}
                  </span>
                }
              </div>
            </div>
          );
          return (
            <div
              key={index}
              onClick={() => {
                setImageView(!imageView);
                setImage(buildImage(item.image).url());
              }}
              className="flex  flex-col gap-y-2 bg-black p-4 w-full md:w-[190px] cursor-pointer"
            >
              {item.image &&
                <img
                  className="md:w-[180px] w-full h-[100px] object-cover"
                  src={buildImage(item.image).url()}
                  alt=""
                />
              }
              <div>
                <span className="font-semibold text-brand uppercase ">
                  {item.title}
                </span>
                <p className="text-xs opacity-60 ">{item.description}</p>
                {item.price &&
                  <span className=" font-semibold text-brand uppercase whitespace-nowrap text-sm">
                    ${item.price}
                  </span>
                }
              </div>
            </div>
          );
        })}
      </div>
      <ViewImage
        setImageView={setImageView}
        url={image}
        imageView={imageView}
      />
    </motion.div>
  );
}

function ViewImage({ imageView, setImageView, url }) {
  return (
    <div
      onClick={() => setImageView(!imageView)}
      className={`${imageView ? " block" : "hidden"
        } fixed top-0 flex left-0 w-full bottom-0 h-[100%] `}
    >
      <div className=" fixed inset-0 bg-black/80 backdrop-blur-sm "></div>
      <div className=" flex flex-col  relative justify-center items-center h-full w-full">
        <button s className="btn mb-2">
          <IoMdClose />
        </button>
        <motion.img
          key={imageView}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.3 }}
          className={` ${imageView ? " scale-100" : " scale-0"
            } transition-all object-cover w-[80%] h-[70vh] md:h-[70vh]`}
          src={url}
          alt=""
        />
      </div>
    </div>
  );
}
