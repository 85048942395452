import { client } from "../sanity-client";
import { GET_EMAIL_QUERY } from "../queries/email";

export class EmailService {
  async getAll() {
    return await client.fetch(GET_EMAIL_QUERY);
  }

  openReservation(to, { name, date, time }) {
    const body = `Hello, my name is ${name} i need a reservation for ${date} at ${time}`;
    window.location.href = `mailto:${to}?subject=Reservation - ${date} at ${time}&body=${body}`;
  }

  openContact(to, { name, subject, message }) {
    const body = `Hello, my name is ${name} \n ${message}`;
    window.location.href = `mailto:${to}?subject=${subject}&body=${body}`;
  }
}
