import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const nav = [
    {
      text: "Contact",
      href: "contact",
      onClick: () => {
        const element = document.getElementById("contact-section");
        element.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      text: "Foods",
      href: "foods",
      onClick: () => {
        const element = document.getElementById("menu-section");
        element.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      text: "Menu Food Truck",
      href: "foodtruck",
    },
  ];

  return (
    <nav>
      <ul className=" hidden md:flex items-center gap-x-3">
        {nav.map((item, index) => (
          <>
            {item.onClick ? (
              <li
                key={index}
                onClick={item.onClick}
                className="nav-item whitespace-nowrap"
              >
                {item.text}
              </li>
            ) : (
              <Link
                key={index}
                to={item.href}
                className="nav-item whitespace-nowrap"
              >
                {item.text}
              </Link>
            )}
          </>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
